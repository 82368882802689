import { start, ToneBufferSource, ToneAudioBuffer } from "tone"
import audioFiles = require('../audio/files.json')

let nextSound = null;
let activeSources = 0;

document.querySelector('button').addEventListener('click', async () => {
	await start()
	makeNewSound()
})

document.querySelector('button').addEventListener('mouseenter', async () => {
	document.querySelector('#title').classList.add('hover')
})

document.querySelector('button').addEventListener('mouseleave', async () => {
	document.querySelector('#title').classList.remove('hover')
})

// follow the mouse
// document.body.addEventListener('mousemove', (e) => {
// 	// rotate the shadow to follow the mouse
// 	const rect = document.querySelector('#title').getBoundingClientRect()
// 	const centerX = (rect.left + rect.right) / 2
// 	const centerY = (rect.top + rect.bottom) / 2
// 	const tan = Math.atan2(centerY - e.clientY, centerX - e.clientX);
// 	const x = 1.5 * Math.cos(tan)
// 	const y = 1.5 * Math.sin(tan)
// 	document.querySelectorAll('#title span').forEach((el: HTMLSpanElement) => {
// 		const colorRegexp = new RegExp(/rgb\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)/gm)
// 		const color = colorRegexp.exec(getComputedStyle(el).textShadow)
// 		if (color) {
// 			el.style.textShadow = `${x.toFixed(3)}px ${y.toFixed(2)}px 1px ${color[0]}`
// 		}
// 	})
// })

async function makeNewSound() {
	const buff = await nextSound;
	document.querySelector('#title').classList.add('active')
	const source = new ToneBufferSource(buff).toDestination()
	source.fadeIn = 0.05
	const now = source.now()
	source.start(now)
	source.playbackRate.setValueAtTime(Math.random() * 0.5, now)
	for (let time = 0.5; time < buff.duration; time += Math.random() * 0.5) {
		const randPlayback = Math.random() + 0.5
		source.playbackRate.linearRampToValueAtTime(randPlayback, now + time)
	}
	activeSources++
	source.onended = () => {
		activeSources--
		if (activeSources === 0) {
			document.querySelector('#title').classList.remove('active')
		}
		// console.log(`activeSources: ${activeSources}`)
	}
	// queue up the next one at the end
	nextSound = loadNextSound();
}

async function loadNextSound() {
	// choose a random audio file
	const randomFile = audioFiles[Math.floor(Math.random() * audioFiles.length)]
	const buff = await ToneAudioBuffer.fromUrl(`./audio/${randomFile}`)
	return buff;
}

// load the first sound
nextSound = loadNextSound();
